var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
      attrs: { "label-position": _vm.device === "mobile" ? "top" : "left" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.warehouseRecord,
            rules: _vm.rules,
            "label-width": "auto",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.warehouseRecord.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.warehouseRecord, "id", $$v)
                  },
                  expression: "warehouseRecord.id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WarehouseRecord.Warehouse"),
                prop: "warehouseId",
                rules: [
                  {
                    required: true,
                    message: _vm.$t("WarehouseRecord.RequiredMessage"),
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.warehouses,
                  label: "name",
                  reduce: function (warehouse) {
                    return warehouse.uuid
                  },
                  disabled: _vm.disableWarehouse,
                },
                model: {
                  value: _vm.warehouseRecord.warehouseId,
                  callback: function ($$v) {
                    _vm.$set(_vm.warehouseRecord, "warehouseId", $$v)
                  },
                  expression: "warehouseRecord.warehouseId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WarehouseRecord.WarehouseArea"),
                prop: "warehouseAreaId",
                rules: [
                  {
                    required: true,
                    message: _vm.$t("WarehouseRecord.RequiredMessage"),
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.warehouseAreas,
                  label: "name",
                  reduce: function (warehouseArea) {
                    return warehouseArea.uuid
                  },
                  disabled: _vm.disableWarehouseArea,
                },
                model: {
                  value: _vm.warehouseRecord.warehouseAreaId,
                  callback: function ($$v) {
                    _vm.$set(_vm.warehouseRecord, "warehouseAreaId", $$v)
                  },
                  expression: "warehouseRecord.warehouseAreaId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WarehouseRecord.RawMaterial"),
                prop: "rawMaterialId",
                rules: [
                  {
                    required: true,
                    message: _vm.$t("WarehouseRecord.RequiredMessage"),
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.rawMaterials,
                  label: "sku",
                  reduce: function (rawMaterial) {
                    return rawMaterial.uuid
                  },
                  disabled: _vm.disableRawMaterial,
                },
                model: {
                  value: _vm.warehouseRecord.rawMaterialId,
                  callback: function ($$v) {
                    _vm.$set(_vm.warehouseRecord, "rawMaterialId", $$v)
                  },
                  expression: "warehouseRecord.rawMaterialId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WarehouseRecord.Action"),
                prop: "action",
                rules: [
                  {
                    required: true,
                    message: _vm.$t("WarehouseRecord.RequiredMessage"),
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.actions,
                  label: "description",
                  reduce: function (action) {
                    return action.name
                  },
                  disabled: "",
                },
                model: {
                  value: _vm.warehouseRecord.action,
                  callback: function ($$v) {
                    _vm.$set(_vm.warehouseRecord, "action", $$v)
                  },
                  expression: "warehouseRecord.action",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.warehouseRecord.action === "stock_transfer"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("WarehouseRecord.ToWarehouse"),
                    prop: "toWarehouseId",
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("WarehouseRecord.RequiredMessage"),
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.warehouses,
                      label: "name",
                      reduce: function (warehouse) {
                        return warehouse.uuid
                      },
                    },
                    on: { input: _vm.onTargetWarehouseSelected },
                    model: {
                      value: _vm.warehouseRecord.toWarehouseId,
                      callback: function ($$v) {
                        _vm.$set(_vm.warehouseRecord, "toWarehouseId", $$v)
                      },
                      expression: "warehouseRecord.toWarehouseId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.warehouseRecord.action === "stock_transfer"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("WarehouseRecord.ToWarehouseArea"),
                    prop: "toWarehouseAreaId",
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("WarehouseRecord.RequiredMessage"),
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.toWarehouseAreas,
                      label: "name",
                      reduce: function (warehouseArea) {
                        return warehouseArea.uuid
                      },
                    },
                    model: {
                      value: _vm.warehouseRecord.toWarehouseAreaId,
                      callback: function ($$v) {
                        _vm.$set(_vm.warehouseRecord, "toWarehouseAreaId", $$v)
                      },
                      expression: "warehouseRecord.toWarehouseAreaId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.warehouseRecord.action === "stock_take" &&
          _vm.rawMaterialUnit != _vm.rawMaterialSubUnit
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("WarehouseRecord.MainQty"),
                    prop: "qty",
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("WarehouseRecord.RequiredMessage"),
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { precision: 0, min: 0, size: "small" },
                    on: { change: _vm.calculatedQty },
                    model: {
                      value: _vm.warehouseRecord.mainqty,
                      callback: function ($$v) {
                        _vm.$set(_vm.warehouseRecord, "mainqty", $$v)
                      },
                      expression: "warehouseRecord.mainqty",
                    },
                  }),
                  _vm._v("\n      " + _vm._s(_vm.rawMaterialUnit) + "\n    "),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.warehouseRecord.action === "stock_take" &&
          _vm.rawMaterialUnit != _vm.rawMaterialSubUnit
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("WarehouseRecord.SubQty"),
                    prop: "qty",
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("WarehouseRecord.RequiredMessage"),
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { precision: 0, min: 0, size: "small" },
                    on: { change: _vm.calculatedQty },
                    model: {
                      value: _vm.warehouseRecord.subqty,
                      callback: function ($$v) {
                        _vm.$set(_vm.warehouseRecord, "subqty", $$v)
                      },
                      expression: "warehouseRecord.subqty",
                    },
                  }),
                  _vm._v(
                    "\n      " + _vm._s(_vm.rawMaterialSubUnit) + "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("WarehouseRecord.QTY"), prop: "qty" } },
            [
              _c("el-input-number", {
                attrs: {
                  precision: 2,
                  min: 0,
                  max:
                    _vm.warehouseRecord.action === "stock_out" ||
                    _vm.warehouseRecord.action === "stock_transfer"
                      ? _vm.warehouseAreaRawMaterials[0].qty
                        ? _vm.warehouseAreaRawMaterials[0].qty
                        : _vm.warehouseRecord.qty
                      : Infinity,
                  size: "small",
                },
                on: { change: _vm.calculatedMainAndSubQty },
                model: {
                  value: _vm.warehouseRecord.qty,
                  callback: function ($$v) {
                    _vm.$set(_vm.warehouseRecord, "qty", $$v)
                  },
                  expression: "warehouseRecord.qty",
                },
              }),
              _vm._v("\n      " + _vm._s(_vm.rawMaterialUnit) + "\n      "),
              _vm.warehouseRecord.action === "stock_take" &&
              _vm.warehouseRecord.qty != _vm.stocktakeOriginalQty
                ? _c("span", [
                    _vm._v(
                      "(Diff: " +
                        _vm._s(
                          parseFloat(
                            parseFloat(
                              _vm.warehouseRecord.qty - _vm.stocktakeOriginalQty
                            ).toFixed(2)
                          )
                        ) +
                        ")"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.warehouseRecord.action === "stock_in"
            ? _c(
                "el-form-item",
                { attrs: { label: "入貨日期", prop: "storeAt" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "picker-options": { firstDayOfWeek: 1 },
                      type: "date",
                      placeholder: "選擇日期",
                    },
                    model: {
                      value: _vm.warehouseRecord.storeAt,
                      callback: function ($$v) {
                        _vm.$set(_vm.warehouseRecord, "storeAt", $$v)
                      },
                      expression: "warehouseRecord.storeAt",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WarehouseRecord.Remark"),
                prop: "remark",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.warehouseRecord.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.warehouseRecord, "remark", $$v)
                  },
                  expression: "warehouseRecord.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", icon: "el-icon-success" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v(_vm._s(_vm.$t("Order.Cancel"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }